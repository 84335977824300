<template>
  <div>
    <!-- 最上边 -->
    <div class="center-top">
      <div class="top-name">张文哲</div>
      <div class="lisitions">接听中</div>
      <div class="top-tome">01:20:20</div>
      <div class="top-tu">
      <div class="top-tu-one"><img src="../../assets/images/images/jingyin_03.jpg" alt=""></div>
      <div class="top-tu-one"><img src="../../assets/images/images/zhuanjie_05.jpg" alt=""></div>
      <div class="top-tu-one"><img src="../../assets/images/images/yiliao_07.jpg" alt=""></div>
      <div class="top-tu-one"><img src="../../assets/images/images/jijiu_09.jpg" alt=""></div>
      <div class="top-tu-one"><img src="../../assets/images/images/jinji_11.jpg" alt=""></div>
      <div class="top-tu-one"><img src="../../assets/images/images/guaduan_13.jpg" alt=""></div>
      </div>
      <div class="top-zhuangtai">
        <span class="my-zhuangtai">我的状态</span>
        <el-select
          v-model="valuezhuangtai"
          placeholder="请选择"
          style="width: 100px; height: 30px"
        >
          <el-option
            v-for="item in optionszhuangtai"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="top-tonghua">01:02:33</div>
      </div>
    </div>
    <!-- tab切换 -->
    <div class="tabs">
      <div class="badge">12</div>
      <div class="badge1">12</div>
      <div class="badge2">12</div>
      <div class="tabs-left">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="常用知识" name="first">
          <div class="search">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <img class="imgs" src="../../assets/images/query.png" alt />
          </div>
          <div class="left">
            <div class="trees">
              <el-container style="height: 500px">
                <el-aside width="200px" class="aside-tree">
                  <el-tree
                    :data="dataList"
                    :props="defaultProps"
                    @node-click="handleNodeClick"
                  >
                  </el-tree>
                </el-aside>
              </el-container>
            </div>
            <div class="right">
              1、选择合适的血压仪
              血压仪种类是非常多的，一般包括汞柱式血压仪、气压表式血压仪和电子血压仪这三种，所以根据自己的情况选择最为合适的血压仪，是正确测量血压的一个前提。
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="法律援助" name="second">
          <div>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="工单编号" width="100">
              </el-table-column>
              <el-table-column prop="name" label="通话对象" width="100">
              </el-table-column>
              <el-table-column prop="address" label="设备号码" width="100">
              </el-table-column>
              <el-table-column prop="time" label="剩余时间" width="100">
              </el-table-column>
              <el-table-column prop="level" label="优先级" width="100">
              </el-table-column>
              <el-table-column prop="times" label="添加时间" width="190">
              </el-table-column>
              <el-table-column label="处理">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="handleEdit(scope.$index, scope.row)"
                    >完成</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="政策解读" name="third">
          <div>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="工单编号" width="100">
              </el-table-column>
              <el-table-column prop="name" label="通话对象" width="100">
              </el-table-column>
              <el-table-column prop="address" label="设备号码" width="100">
              </el-table-column>
              <el-table-column prop="time" label="剩余时间" width="100">
              </el-table-column>
              <el-table-column prop="level" label="优先级" width="100">
              </el-table-column>
              <el-table-column prop="times" label="添加时间" width="190">
              </el-table-column>
              <el-table-column label="处理">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="handleEdit(scope.$index, scope.row)"
                    >完成</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关爱慰问" name="fourth">
          <div>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="工单编号" width="100">
              </el-table-column>
              <el-table-column prop="name" label="通话对象" width="100">
              </el-table-column>
              <el-table-column prop="address" label="设备号码" width="100">
              </el-table-column>
              <el-table-column prop="time" label="剩余时间" width="100">
              </el-table-column>
              <el-table-column prop="level" label="优先级" width="100">
              </el-table-column>
              <el-table-column prop="times" label="添加时间" width="190">
              </el-table-column>
              <el-table-column label="处理">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="handleEdit(scope.$index, scope.row)"
                    >完成</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="通话记录" name="five">
          <div class="tonghua">
            2021年10月8日<i class="el-icon-video-play"></i>
            当日累计通话：{{this.totalTime1}}
          </div>
          <div>
            <el-table :data="tableData1" height="690" style="width: 100%">
              <el-table-column prop="name" label="通话对象" width="180">
              </el-table-column>
              <el-table-column prop="startTime" label="开始时间" width="180">
              </el-table-column>
              <el-table-column prop="bridgeDuration" label="通话时长"> </el-table-column>
              <el-table-column prop="qiniuUrl" label="回放录音">
                <i class="el-icon-video-play"></i>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="全部代办" name="six">
            <div>
              <el-table :data="tableData2" style="width: 100%" height="500">
                <el-table-column prop="workNumber" label="工单编号" width="90">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column prop="equipmentId" label="设备号码" width="100">
                </el-table-column>
                <el-table-column prop="remainingTime" label="剩余时间" width="90">
                </el-table-column>
                <el-table-column prop="priority" label="优先级" width="80">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column prop="userId" label="关联客服" width="100">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="改配">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >改配</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
        </el-tab-pane>
      </el-tabs>
      </div>
      <!-- 右边 -->
      <div class="bigRigth">
        <el-tabs
          v-model="activeName1"
          type="card"
          @tab-click="handleClick1"
          class="bigRight-tab"
        >
          <el-tab-pane label="用户信息" name="first">
            <div class="information">
              <div class="inforTop">
                <div class="baseInformation">基础信息</div>
                <div class="neirong">
                  <el-form :model="formMember" :inline="true">
                    <el-col :span="10">
                      <el-form-item label="姓 名 ">
                        <el-input
                          v-model="formMember.name"
                          autocomplete="off"
                          style="width: 140px; margin-left: 11px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="备用号码">
                        <el-input
                          v-model="formMember.phone"
                          autocomplete="off"
                          style="width: 140px; margin-left: 15px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="家庭ID">
                        <el-input
                          v-model="formMember.familyId"
                          autocomplete="off"
                          style="width: 140px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item label="紧急联系人">
                        <el-input style="width: 60px" v-model="formMember.emergencyContactOne"></el-input>
                        <el-input
                          v-model="formMember.emergencyContactOnePhone"
                          autocomplete="off"
                          style="width: 140px"
                        ></el-input
                        ><br />
                        <el-input style="width: 60px" v-model="formMember.emergencyContactTwo"></el-input>
                        <el-input
                          v-model="formMember.emergencyContactTwoPhone"
                          autocomplete="off"
                          style="width: 140px"
                        ></el-input
                        ><br />
                        <el-input style="width: 60px" v-model="formMember.emergencyContactThree"></el-input>
                        <el-input
                          v-model="formMember.emergencyContactThreePhone"
                          autocomplete="off"
                          style="width: 140px"
                        ></el-input>
                      </el-form-item> </el-col
                    ><br />
                    <el-col :span="10">
                      <el-form-item label="性 别">
                        <el-select
                          v-model="value"
                          placeholder="请选择"
                          style="width: 140px; margin-left: 11px"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="inforbirth">
                      <el-form-item label="生 日">
                        <el-date-picker
                          v-model="value1"
                          type="date"
                          placeholder="选择日期"
                          style="width: 140px; margin-left: 11px"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="30" class="dengji"> -->
                    <el-form-item label="登记位置" class="dengji">
                      <el-select v-model="value2" style="width: 85px">
                        <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select v-model="value3" style="width: 85px">
                        <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select v-model="value4" style="width: 85px">
                        <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-input
                  class="inforInput"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="formMember.address">
                  </el-input>
                    <el-col :span="16" class="shiji">
                      <el-form-item label="实际位置">
                        <div class="shijibox">
                          {{formMember.address}}
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="jihuo">
                      <el-form-item label="激活日期">
                        <el-input
                          v-model="formMember.createDate"
                          autocomplete="off"
                          style="width: 110px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" class="daoqi">
                      <el-form-item label="到期日期">
                        <el-input
                          v-model="formMember.updateDate"
                          autocomplete="off"
                          style="width: 110px"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form>
                </div>
              </div>
             <div class="big-center"> 
              <div class="leftcenter">
                <span class="span1">生活状况</span>
                <table>
                  <tr>
                    <td>居住情况</td>
                    <td>
                      <el-select v-model="valueLive" style="width: 100px">
                        <el-option
                          v-for="item in live"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </td>
                  </tr>
                  <tr>
                    <td>智力状态</td>
                    <td>
                      <el-select v-model="valuezhiLi" style="width: 100px">
                        <el-option
                          v-for="item in zhiLi"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </td>
                  </tr>
                  <tr>
                    <td>性格特征</td>
                    <td>
                      <el-select v-model="valuexingge" style="width: 100px">
                        <el-option
                          v-for="item in xingge"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </td>
                  </tr>
                  <tr>
                    <td>身体状态</td>
                    <td>
                      <div class="tdspan">
                        <span
                          >语言：
                          <el-select v-model="valueyuyan" style="width: 100px">
                            <el-option
                              v-for="item in yuyan"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </span>
                      </div>
                      <div class="tdspan">
                        <span
                          >听力：
                          <el-select v-model="valuetingli" style="width: 100px">
                            <el-option
                              v-for="item in tingli"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </span>
                      </div>
                      <div class="tdspan">
                        <span
                          >视力：
                          <el-select v-model="valueshili" style="width: 100px">
                            <el-option
                              v-for="item in shili"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </span>
                      </div>
                      <div class="tdspan">
                        <span>
                          行动：
                          <el-select
                            v-model="valuexingdong"
                            style="width: 100px"
                          >
                            <el-option
                              v-for="item in xingdong"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>慢性病史</td>
                    <td>
                      <el-select
                        v-model="valuemanxing"
                        placeholder="请选择"
                        style="width: 150px"
                      >
                        <el-option
                          v-for="item in manxing"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="infor-img"><img src="../../assets/images/add.png" alt=""></td>
                  </tr>
                </table>
              </div>
              <div class="rightcenter">
                <span class="span1">健康数据</span>
                <table>
                  <tr>
                    <td>血压</td>
                    <td>{{forBody.bloodPressure}}</td>
                  </tr>
                  <tr>
                    <td>血糖</td>
                    <td>
                      <div class="tdspan">
                        <span>{{forBody.bloodSugar}}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>心率</td>
                    <td>{{forBody.heartRate}}</td>
                  </tr>
                  <tr>
                    <td>体温</td>
                    <td>{{forBody.temperature}}</td>
                  </tr>
                  <tr>
                    <td>睡眠</td>
                    <td>{{forBody.sleep}}</td>
                  </tr>
                  <tr>
                    <td>用药安排</td>
                    <td>
                        <div class="tdspan">
                          <span>{{morningbefor.medicationDate}} {{morningbefor.drugCode}} {{morningbefor.unit}}</span>
                        </div>
                        <div class="tdspan">
                          <span>{{morningafter.medicationDate}} {{morningafter.drugCode}} {{morningafter.unit}}</span>
                        </div>
                        <div class="tdspan">
                          <span>{{lunchbefor.medicationDate}} {{lunchbefor.drugCode}} {{lunchbefor.unit}}</span>
                        </div>
                        <div class="tdspan">
                          <span>{{lunchafter.medicationDate}} {{lunchafter.drugCode}} {{lunchafter.unit}}</span>
                        </div>
                        <div class="tdspan">
                          <span>{{eveningbefor.medicationDate}} {{eveningbefor.drugCode}} {{eveningbefor.unit}}</span>
                        </div>
                        <div class="tdspan">
                          <span>晚餐后 xxxx 1颗</span>
                        </div>
                      </td>
                  </tr>
                </table>
                <span class="buttons baocun">保存</span>
              </div>
             </div> 
            </div>
          </el-tab-pane>
          <el-tab-pane label="工单管理" name="second">
            <div class="history">
              <div class="historydan">历史工单</div>
              <ul class="history-ul">
                <li>
                  <div class="suggest">反馈建议</div>
                  <span class="times">2021-10-5 10:22:25</span
                  ><span class="person">客服</span
                  ><span class="times">0089</span
                  ><span class="person-id">ID</span
                  ><span class="times">84763211</span>
                  <div class="content">提高手环音量</div>
                </li>
                <li>
                  <div class="suggest">反馈建议</div>
                  <span class="times">2021-10-5 10:22:25</span
                  ><span class="person">客服</span
                  ><span class="times">0089</span
                  ><span class="person-id">ID</span
                  ><span class="times">84763211</span>
                  <div class="content">提高手环音量</div>
                </li>
                <li>
                  <div class="suggest">反馈建议</div>
                  <span class="times">2021-10-5 10:22:25</span
                  ><span class="person">客服</span
                  ><span class="times">0089</span
                  ><span class="person-id">ID</span
                  ><span class="times">84763211</span>
                  <div class="content">提高手环音量</div>
                </li>
                <li>
                  <div class="suggest">反馈建议</div>
                  <span class="times">2021-10-5 10:22:25</span
                  ><span class="person">客服</span
                  ><span class="times">0089</span
                  ><span class="person-id">ID</span
                  ><span class="times">84763211</span>
                  <div class="content">提高手环音量</div>
                </li>
                <li>
                  <div class="suggest">反馈建议</div>
                  <span class="times">2021-10-5 10:22:25</span
                  ><span class="person">客服</span
                  ><span class="times">0089</span
                  ><span class="person-id">ID</span
                  ><span class="times">84763211</span>
                  <div class="content">提高手环音量</div>
                </li>
              </ul>
            </div>
            <div class="create-order">
              <div class="historydan1">创建工单</div>
              <span>
                <el-select
                  v-model="valueOrder"
                  placeholder="请选择"
                  style="width: 120px"
                >
                  <el-option
                    v-for="item in historyOrder"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span>
              <div class="infor-content">
                <div class="inforwrite">
                  <el-input
                    v-model="historyinput"
                    style="width: 150px; margin: 10px 10px 0px 10px"
                  ></el-input>
                  <el-select v-model="valueOrdersex" style="width: 100px">
                    <el-option
                      v-for="item in historyOrdersex"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="history-phone">18369563247</div>
                  <el-select
                    v-model="valueOrderProvince"
                    style="width: 125px; margin: 10px 10px 0px 10px"
                    placeholder="选择省份"
                  >
                    <el-option
                      v-for="item in historyProvince"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select v-model="valueOrderCity" style="width: 125px" placeholder="选择城市">
                    <el-option
                      v-for="item in historyOrderCity"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="fenglei">业务分类</div>
                  <div class="radio-dan">
                    <el-radio v-model="radio" label="1">售后服务</el-radio>
                    <el-radio v-model="radio" label="2">商务合作</el-radio>
                    <el-radio v-model="radio" label="3">投诉建议</el-radio>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span
                      ><el-radio v-model="radio" label="4"
                        >需要回电</el-radio
                      ></span
                    >
                    <span>
                      <el-select v-model="valueOrdertime" style="width: 80px">
                        <el-option
                          v-for="item in historyOrdertime"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        v-model="valueOrderputong"
                        style="width: 80px"
                      >
                        <el-option
                          v-for="item in historyOrderputong"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <div>
                      <span
                        ><el-radio v-model="radio" label="5"
                          >本机回电</el-radio
                        ></span
                      >
                      <span class="benji-phone">12639875633</span>
                      <span class="buttons">提交</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="远程协助" name="third">远程协助</el-tab-pane>
          <el-tab-pane label="生活服务" name="fourth">生活服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      //最上边的客服状态
      valuezhuangtai: "空闲",
      optionszhuangtai: [
        {
          value: "选项1",
          label: "空闲",
        },
        {
          value: "选项2",
          label: "繁忙",
        },
      ],
      labelPosition: 'right',
      activeName: "first",
      activeName1: "first",
      input: "",
      tabPosition: "left",
      //性别下拉
      options: [
        {
          value: "选项1",
          label: "男",
        },
        {
          value: "选项2",
          label: "女",
        },
      ],
      value: "男",
      value1: "", //日期下拉
      value2: [], //登记位置
      value3: [], //登记位置
      value4: [], //登记位置
      options2: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      //生活状况 居住
      live: [
        {
          value: "选项1",
          label: "独居（无保姆）",
        },
        {
          value: "选项2",
          label: "与子女住",
        },
      ],
      valueLive: "独居",
      zhiLi: [
        {
          value: "选项1",
          label: "正常",
        },
        {
          value: "选项2",
          label: "不正常",
        },
      ],
      valuezhiLi: "正常",
      xingge: [
        {
          value: "选项1",
          label: "外向/热情",
        },
        {
          value: "选项2",
          label: "内向",
        },
      ],
      valuexingge: "外向/热情",
      yuyan: [
        {
          value: "选项1",
          label: "正常",
        },
        {
          value: "选项2",
          label: "不正常",
        },
      ],
      valueyuyan: "正常",
      tingli: [
        {
          value: "选项1",
          label: "正常",
        },
        {
          value: "选项2",
          label: "不正常",
        },
      ],
      valuetingli: "正常",
      shili: [
        {
          value: "选项1",
          label: "弱",
        },
        {
          value: "选项2",
          label: "强",
        },
      ],
      valueshili: "弱",
      xingdong: [
        {
          value: "选项1",
          label: "正常",
        },
        {
          value: "选项2",
          label: "不正常",
        },
      ],
      valuexingdong: "正常",
      manxing: [
        {
          value: "选项1",
          label: "高血压",
        },
        {
          value: "选项2",
          label: "糖尿病",
        },
        {
          value: "选项3",
          label: "高脂血综合征",
        },
      ],
      valuemanxing: "高血压",
      //创建工单中的 信息记录下拉
      historyOrder: [
        {
          value: "选项1",
          label: "信息记录",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
      ],
      valueOrder: "",
      historyinput: "",
      historyOrdersex: [
        {
          value: "选项1",
          label: "男",
        },
        {
          value: "选项2",
          label: "女",
        },
      ],
      valueOrdersex: "",
      historyProvince: [
        {
          value: "选项1",
          label: "信息记录",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
      ],
      valueOrderProvince: "",
      historyOrderCity: [
        {
          value: "选项1",
          label: "信息记录",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
      ],
      valueOrderCity: "",
      radio: "1",
      textarea: "",
      historyOrdertime: [
        {
          value: "选项1",
          label: "24小时",
        },
        {
          value: "选项2",
          label: "6小时",
        },
        {
          value: "选项3",
          label: "8小时",
        },
      ],
      valueOrdertime: "",
      historyOrderputong: [
        {
          value: "选项1",
          label: "普通",
        },
        {
          value: "选项2",
          label: "会员",
        },
      ],
      valueOrderputong: "",
      //表格数据
      tableData: [
        {
          date: "00001",
          name: "王小虎",
          address: "3221133",
          time: "2小时",
          level: "普通",
          times: "2021年10月8日 13:25:23",
        },
        {
          date: "00002",
          name: "王小虎",
          address: "3221133",
          time: "2小时",
          level: "普通",
          times: "2021年10月8日 13:25:23",
        },
        {
          date: "00003",
          name: "王小虎",
          address: "3221133",
          time: "2小时",
          level: "普通",
          times: "2021年10月8日 13:25:23",
        },
        {
          date: "00004",
          name: "王小虎",
          address: "3221133",
          time: "2小时",
          level: "普通",
          times: "2021年10月8日 13:25:23",
        },
      ],
      tableData1: [],
      totalTime1: "", //通话记录总时长
      tableData2: [],
      dataList: [
        {
          label: "血压相关",
          children: [
            {
              label: "如何正确测量血压",
            },
            {
              label: "血压分级标准",
            },
          ],
        },
        {
          label: "血糖相关",
          children: [
            {
              label: "血糖 1",
            },
            {
              label: "血糖 2",
            },
          ],
        },
        {
          label: "血脂相关",
          children: [
            {
              label: "高血脂形成原因",
            },
            {
              label: "高血脂主要症状",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      formMember: {},//信息查询的基础信息
      form:{},
      forBody:{},//健康数据
      morningbefor:{},//早餐前
      morningafter:{},//早餐后
      lunchbefor:{},//午餐前
      lunchafter:{},//午餐后
      eveningbefor:{},//晚餐前
    };
  },
  created(){
    //获取通话记录
    apiShout.Wconverse().then((res) => {
      //console.log("通话记录：", res.data);
      if (res.data.code === 200) {
        // 对通话时长的处理
        for (let i = 0; i < res.data.result.data.length; i++) {
          if (res.data.result.data[i].bridgeDuration == null) {
            res.data.result.data[i].bridgeDuration = "";
          } else {
            res.data.result.data[i].bridgeDuration=this.format(res.data.result.data[i].bridgeDuration)
          }
        }
        this.tableData1 = res.data.result.data;
        this.totalTime1 = this.format(res.data.result.totalTime);
      } else if (res.data.msg == "token不正确") {
        //跳转到登录页面
        this.$router.push("/");
        this.$message.error("token不正确");
      }
    });
    //查询代办工单
    apiShout.getCommission().then(res=>{
      //console.log('全部代办：',res.data);
      if(res.data.code===200){
        //对于日期的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].createDate == null) {
              res.data.result.data[i].createDate = "";
            } else {
              let a = res.data.result.data[i].createDate;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              let h = dd.getHours();
              let minute = dd.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = dd.getSeconds();
              second = second < 10 ? "0" + second : second;
              // res.data.result.data[i].createDate =
              //   y + "-" + m + "-" + d;
              res.data.result.data[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
        //对于剩余时间的处理
        for(let j=0;j<res.data.result.data.length;j++){
          res.data.result.data[j].remainingTime=res.data.result.data[j].remainingTime+'小时';
        }
        this.tableData2=res.data.result.data;
      }
    });
     //查询用户信息
    apiShout.getuserInfor({phone:'13401107330'}).then(dat=>{
      console.log('用户信息：',dat.data);
      if(dat.data.code===200){
        //对于生日的处理
        let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday=
                y + "-" + m + "-" + d;
        //对于激活日期的处理
        let  b= dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate=
                y1 + "-" + m1 + "-" + d1;
        //对于到期日期的处理
        let  c= dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate=
                y2 + "-" + m2 + "-" + d2;
        this.formMember=dat.data.result.member;
        this.value1=dat.data.result.member.birthday;
        this.value2=dat.data.result.member.city;
        this.value3=dat.data.result.member.city;
        this.value4=dat.data.result.member.county;
        this.valueLive=dat.data.result.memberInfo.live;
        this.valuezhiLi=dat.data.result.memberInfo.intellect;
        this.valuexingge=dat.data.result.memberInfo.character;
        this.valueyuyan=dat.data.result.memberInfo.language;
        this.valuetingli=dat.data.result.memberInfo.hearing;
        this.valueshili=dat.data.result.memberInfo.vision;
        this.valuexingdong=dat.data.result.memberInfo.action;
        this.valuemanxing=dat.data.result.memberInfo.illness;
        //健康数据
        this.forBody=dat.data.result.memberInfo;
        for(let i=0;i<dat.data.result.remind.length;i++){
          this.morningbefor=dat.data.result.remind[0];
          this.morningafter=dat.data.result.remind[1];
          this.lunchbefor=dat.data.result.remind[2];
          this.lunchafter=dat.data.result.remind[3];
          this.eveningbefor=dat.data.result.remind[4];
        }
      }
    });
  },
  methods: {
    //点击树事件
    handleNodeClick(obj) {
      console.log("---", obj);
    },
    handleClick1(tab, event) {
      console.log(tab, event);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    // 将秒转为00:00:00格式
    format(seconds) {
			let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
			seconds -= 3600 * hour;
			let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
			seconds -= 60 * min;
			let sec = seconds >= 10 ? seconds : '0' + seconds;
			return hour + ':' + min + ':' + sec;
		}
  },
};
</script>

<style lang='less' scoped>
//标记
.badge{
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 210px;
  top: 115px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge1{
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 323px;
  top: 115px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge2{
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 435px;
  top: 115px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
// 最上边
.center-top{
  position: relative;
  margin: 10px 5px;
  height: 80px;
  line-height: 80px;
}
.top-name{
  font-size: 30px;
  color: #92aca7;
  margin-left: 20px;
  float: left;
}
.lisitions{
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #76b935;
  color: #fff;
  border-radius: 20px;
  float: left;
  margin:28px 10px 10px 10px;
  
}
.top-tome{
  float: left;
}
.top-tu{
  display: inline-block;
  margin-left: 50px;
}
.top-tu-one{
  float: left;
  margin-top: 15px;
}
.top-tu-one:nth-child(2){
  margin-left: 10px;
}
.top-tu-one:nth-child(3){
  margin-left: 10px;
}
.top-tu-one:nth-child(4){
  margin-left: 10px;
}
.top-tu-one:nth-child(5){
  margin-left: 10px;
}
.top-tu-one:last-child{
  margin-left: 13px;
}
.top-tu:first-child{
  margin-left: 50px;
}
//其他
.baocun{
  position: absolute;
  left: 500px;
  bottom: 10px;
}
.home .el-aside {
  background: #fff;
}
.tonghua {
  text-align: center;
  color: rgb(192, 189, 189);
}
/deep/ .el-table th.el-table__cell > .cell {
  background-color: #92aca7;
  color: #fff;
  font-size: 17px;
  padding: 10px;
}
/deep/ .el-table tr:nth-child(odd) {
  background-color: #e5f4f1;
}
/deep/ .el-table tr:nth-child(even) {
  background-color: #fff;
}
// .bigRight-tab{
//   float:right;
// }
.tabs {
  display: flex;
  //margin: 20px 20px;
  margin-left: 20px;
  // width: 52%;
  // border: 1px solid #eee;
  font-size: 16px;
  /deep/ .el-tabs__item {
    color: #d78673;
    font-size: 18px;
  }
  /deep/ .el-tabs__item.is-active {
    color: #925d51;
    background-color: #d78673;
  }
  // /deep/ .el-tabs--left .el-tabs__item.is-left {
  //   color: #92aca7;
  // }
  /deep/ .el-tree-node__label.aside-tree {
    color: #fff;
    background-color: #d78673;
  }
  /deep/ .el-input__inner {
    height: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
    //width: 230px;
  }
  /deep/ .el-tree-node__label {
    color: #92aca7;
    font-size: 18px;
  }
  .search {
    display: flex;
    width: 300px;
    font-size: 16px;
    .imgs {
      width: 53px;
      height: 31px;
      margin-left: 10px;
    }
  }
  .left {
    display: flex;
    margin: 10px 0;
    font-size: 16px;
    color: #a8a8a8;
    .el-tabs--left .el-tabs__item.is-left {
      text-align: center;
    }
  }
  .right {
    padding: 15px;
  }
  .tabs-left{
    // float: left;
    flex: 1;
  }
  //右边的
  .bigRigth {
    // float: left;
    flex: 1;
    height: 1050px;
    // position: absolute;
    // right: 30px;
    // top: 138px;
    // width: 790px;
  }
  // /deep/ .el-tabs__nav-scroll{
  //   float:right;
  // }
  [class*="el-col-"] {
    height: 40px;
  }
  .inforTop {
    height: 320px;
    font-size: 19px;
    // background: lemonchiffon;
  }
  .inforbirth {
    position: absolute;
    left: 22px;
    top: 166px;
  }
  .dengji {
    position: absolute;
    left: 22px;
    top: 215px;
  }
  .inforInput {
    position: absolute;
    left: 110px;
    top: 260px;
    width: 255px;
    border-color: #eee;
  }
  .shiji {
    position: absolute;
    left: 20px;
    top: 300px;
  }
  .shijibox {
    //border: 1px solid #ebeaea;
    font-size: 16px;
    color: rgb(185, 179, 179);
  }
  .jihuo {
    position: absolute;
    left: 400px;
    top: 220px;
  }
  .daoqi {
    position: absolute;
    left: 400px;
    top: 260px;
  }

  .information {
    // background: rgb(238, 236, 236);
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    width: 600px;
    float: left;
     border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .baseInformation {
      color: #92aca7;
      font-size: 19px;
      font-weight: bolder;
    }
  }
  /deep/ .el-form--inline .el-form-item__label {
    font-size: 17px;
    font-weight: bolder;
    color: #a8a8a8;
  }
  .big-center{
    display: flex;
  }
  .left,
  .leftcenter,
  .rightcenter {
    background: #ffffff;
    border-radius: 15px;
    // margin: 0 10px;
    // float: left;
    flex: 1;
    // 大字
    .span1 {
      display: block;
      font-size: 19px;
      font-weight: bolder;
      color: #92aca7;
      text-align: left;
      // line-height: 44px;
    }
    table {
      width: 100%;
      margin-top: 20px;
      tr {
        // margin-bottom: 17px;
        display: flex;
        td:first-child {
          color: #a8a8a8;
          font-weight: bolder;
          font-size: 17px;
          width: 80px;
        }
        td:nth-child(2) {
          font-size: 16px;
          color: #a8a8a8;
          padding-right: 15px;
          flex: 1;
        }
        td {
          .tdspan {
            line-height: 35px;
          }
          .el-select {
            width: 100%;
            .el-input__inner {
              border: 1px solid #ffffff;
              color: #c8c8c8;
              font-size: 16px;
            }
          }
        }
        .infor-img{
          margin-left: 110px;
        }
      }
    }
  }

  //历史工单
  .history {
    width: 95%;
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .historydan {
    color: #92aca7;
    font-size: 19px;
    font-weight: bolder;
  }
  .history-ul {
    height: 350px;
    //background-color: #d78673;
    overflow: auto;
  }
  .suggest {
    width: 80px;
    height: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #92aca7;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    margin: 10px;
  }
  .person {
    color: #92aca7;
    font-size: 16px;
    margin: 10px 5px 10px 20px;
  }
  .person-id {
    color: #92aca7;
    font-size: 16px;
    margin: 10px 5px 10px 20px;
  }
  .times {
    color: rgb(192, 188, 188);
    font-size: 14px;
  }
  .content {
    color: rgb(192, 188, 188);
    font-size: 14px;
    margin: 0px 0px 10px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d4d4;
  }
  //历史工单的 创建工单
  .historydan1 {
    color: #92aca7;
    font-size: 19px;
    font-weight: bolder;
    display: inline-block;
  }
  .create-order {
    width: 95%;
    border: 1px solid #f0eeee;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .infor-content {
    display: flex;
  }
  .inforwrite {
    width: 325px;
    height: 200px;
    border: 1px solid #d6d4d4;
  }
  .history-phone {
    margin-left: 20px;
    color: rgb(221, 216, 216);
  }
  .fenglei {
    color: #92aca7;
    font-size: 15px;
    margin-left: 20px;
  }
  .radio-dan {
    margin: 10px 10px 10px 20px;
  }
  .his-right {
    margin: 0px 10px 10px 10px;
  }
  .infor-bottom {
    margin-top: 10px;
  }
  .benji-phone {
    margin-left: 10px;
    color: rgb(199, 196, 196);
  }
  .buttons{
    width: 50px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    padding: 5px;
    border-radius: 25px;
    background-color:#d78673;
    color: #ffffff;
    font-size: 14px;
    display: inline-block;
    margin: 10px;
  }
}
//tabs右浮
/deep/ .bigRigth .el-tabs__nav-scroll{
  float:right;
}
//tabs边框
/deep/ .el-tabs__content{
  border: 1px solid #eee;
}
//最上边的第二种样式
.top-zhuangtai {
  float: right;
  // position: absolute;
  // top: -10px;
  // left: 1291px;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.my-zhuangtai {
  margin-right: 20px;
}
.top-tonghua {
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>